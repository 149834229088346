/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .container-content .comeos-detailoverview .detailoverviewtype {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.altai-theme-content #bodyView .container-content .comeos-detailoverview section.comeos-detailoverview-introduction {
  padding: 40px 0 40px 0!important;
  font-size: 1.1em;
  line-height: 1.7em;
  text-align: left;
  margin: 0 0 0 0 ;
}
.altai-theme-content #bodyView .container-content .comeos-detailoverview section.comeos-detailoverview-introduction p {
  padding-top: 0;
  font-size: 1.1em;
  line-height: 1.7em;
  text-align: left;
  margin: 0 0 0 0;
}
